import { OrbitControls } from '@react-three/drei';
import { gsap } from 'gsap'; // Import GSAP
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import { Cube, DropdownMenu, Star } from './App';

// import { Planets } from './App';


function InteractiveMesh() {
  const { camera } = useThree();
  const originalPosition = useRef(null);

  // Use useEffect to run once on mount and capture the camera's original position
  useEffect(() => {
    originalPosition.current = { ...camera.position };
  }, [camera.position]); // camera.position is a dependency, but in practice, it should be stable

  const onClick = () => {

    // Example target position for the zoom effect
    const targetPosition = { ...camera.position, z: camera.position.z + 500 };
    gsap.to(camera.position, {
      x: targetPosition.x,
      y: targetPosition.y,
      z: targetPosition.z,
      duration: 2
    });


  }

  const onPointerEnter = () => {
    // Return to the original position
    if (originalPosition.current) {
      gsap.to(camera.position, {
        x: originalPosition.current.x,
        y: originalPosition.current.y,
        z: originalPosition.current.z,
        duration: 1
      });
    }
  };

  return (





      <mesh onClick={onClick} onPointerEnter={onPointerEnter}>


        {/* Assuming Cube is a valid component that renders correctly */}
        <Cube />
      </mesh>
      

   
  )
   
}

export function Event({ position, id, setSelectedEvent, selectedEventId }) {
  const meshRef = useRef();
  const [hovered, setHovered] = useState(false);

  // Toggle showing event details
  const toggleEventDetails = () => {
    if (selectedEventId === id) {
      setSelectedEvent(null); // Close if the same event is clicked again
    } else {
      setSelectedEvent(id); // Show new event details
    }
  };

  useFrame(() => {
    meshRef.current.scale.set(hovered ? 1.2 : 1, hovered ? 1.2 : 1, hovered ? 1.2 : 1);
  });

  return (
    <mesh
      ref={meshRef}
      position={position}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      onClick={toggleEventDetails}>
      <sphereGeometry args={[0.1, 16, 16]} />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'white'} />
    </mesh>
  );
}



export function OrbitalVisualization() {

  // Stars background
  const stars = useMemo(() => {
    return new Array(600).fill().map((_, i) => <Star key={i} />);
  }, []);


  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls />
      <Cube />
      {stars}
    </Canvas>
  );



}

function TimelineVisualization({ setSelectedEvent, selectedEventId }) {
  const events = useMemo(() => [
    { id: 1, position: [-2, 0, 0], title: 'Event 1' },
    // Add additional events as needed
  ], []);

  // Stars background
  const stars = useMemo(() => {
    return new Array(600).fill().map((_, i) => <Star key={i} />);
  }, []);


  return (
    <Canvas>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} />
      <OrbitControls />
      <Cube />
      <InteractiveMesh />
      {/* <Planets/> */}

      {stars}


      {events.map((event) => (
        <Event
          key={event.id}
          id={event.id}
          position={event.position}
          setSelectedEvent={setSelectedEvent}
          selectedEventId={selectedEventId}
        />
      ))}
    </Canvas>
  );
}

function Timeline() {
  const [selectedEventId, setSelectedEventId] = useState(null);

  return (
    <div style={styles.app}>

      <DropdownMenu />
      <h1>Diya Dhyani</h1>
      <div style={styles.canvasContainer}>
        <TimelineVisualization setSelectedEvent={setSelectedEventId} selectedEventId={selectedEventId} />
      </div>
      {selectedEventId && (
        <div style={styles.eventDetails} className='App'>
          <p2>Welcome To My Galaxy</p2>
          {/* <button onClick={() => setSelectedEventId(null)} style={styles.closeButton}>
            
          </button> */}
        </div>
      )}
    </div>
  );
}


// Event component remains the same



// Update styles accordingly
export const styles = {
  app: {
    position: 'relative',
    
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black', // Ensure space theme is always dark
    color: 'white',
    overflow: 'hidden',


 
 
  },
  canvasContainer: {
    width: '100%',
    height: '75%',
  },
  eventDetails: {
    display: 'flex',
    justifyContent: 'center',
    top: '20px',
    left: '20px',
    position: 'absolute',

    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',

    color: 'white',
    zIndex: 10,
  },
  closeButton: {
    marginTop: '10px',
    backgroundColor: '#333',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default Timeline;